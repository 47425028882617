import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { UtilService } from 'src/app/services/util.service';
import { DataService, OfferType } from '../../services/data.service';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit {
  offerTypes: OfferType[];
  searchTermControl = new FormControl();

  constructor(
    public dataService: DataService,
    public utilsService: UtilService
  ) {}

  categoryChecked(category): boolean {
    for (const subcategory of category.offer_types) {
      if (!this.dataService.categorySelected(subcategory.name)) {
        return false;
      }
    }
    return true;
  }

  toggleSubCategories(category): void {
    const nextCategoryCheckboxValue = !this.categoryChecked(category);
    for (const subcategory of category.offer_types) {
      this.dataService.forceCategory(subcategory, nextCategoryCheckboxValue);
    }
  }

  ngOnInit(): void {
    this.searchTermControl.setValue(this.dataService.searchTerm);
    this.searchTermControl.valueChanges.pipe(debounceTime(500)).subscribe(value => {
      this.dataService.searchTerm = value;
      this.dataService.filterResults();
    });

    this.dataService.getOfferTypes().then((unorderedDepartments) => {
      this.dataService.offerTypes = this.utilsService.orderDepartments(
        unorderedDepartments
      );
    });
  }
}
