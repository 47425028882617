import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import stem from 'snowball-german';
import { environment } from '../../environments/environment';
import Offer from '../definitions/Offer.interface';
import { OfferBooking } from '../components/offer-booking-body/offer-booking-body.component';
import Municipality from '../definitions/Municipality.interface';

export interface OfferType {
  name: string;
  dropdown_fields: DropdownField[];
}

export interface Departments {
  name: string;
  order_index?: number;
  offer_types: OfferType[];
}

export interface ErrorMessage {
  errors: [];
}

export interface DropdownOption {
  name: string;
  dropdown_field_name?: string;
  selected?: boolean;
  offer_types?: OfferType[];
}

export interface DropdownField {
  name: string;
  field_name: string;
  dropdown_options: DropdownOption[];
}

@Injectable({
  providedIn: 'root'
})
export class DataService {
  API_URL = environment.apiUrl;
  offers: Offer[] = [];
  offerChanged: Subject<Offer> = new Subject<Offer>();
  filteredOffers: Offer[] = [];
  selectedCategories = [];
  dropdownFields: DropdownField[] = [];
  selectedOptions: DropdownOption[] = [];
  offerTypes: Departments[] = [];
  standardFilters = ['Region', 'Institution', 'Zielgruppe'];
  searchTerm = '';
  private searchTerms: string[];
  searchExcludesAttributes = ['intensity_filter'];

  constructor(private http: HttpClient) {
    this.updateDropdownFields();
  }

  getOffer(id: string | number): Promise<Offer> {
    return this.http.get<Offer>(`${this.API_URL}/offers/${id}`).toPromise();
  }

  getOffers(): Promise<Offer[]> {
    return this.http.get<Offer[]>(`${this.API_URL}/offers`).toPromise();
  }

  bookOffer(offerBooking: OfferBooking): Promise<void> {
    return this.http
      .post<void>(`${this.API_URL}/offer_bookings`, offerBooking)
      .toPromise();
  }

  getOfferTypes(): Promise<Departments[]> {
    return this.http
      .get<Departments[]>(`${this.API_URL}/departments`)
      .toPromise();
  }

  getDropdownFields(): Promise<DropdownField[]> {
    return this.http
      .get<DropdownField[]>(`${this.API_URL}/dropdown_fields`)
      .toPromise();
  }

  getMunicipalities(): Promise<Municipality[]> {
    return this.http
      .get<Municipality[]>(`${this.API_URL}/municipalities`)
      .toPromise();
  }

  async updateOffers(): Promise<Offer[]> {
    this.offers = await this.getOffers();
    this.filteredOffers = this.offers;
    return;
  }

  async updateDropdownFields(): Promise<DropdownField[]> {
    this.dropdownFields = await this.getDropdownFields();
    this.dropdownFields.map((dropdownField) => {
      dropdownField.dropdown_options.forEach((option) => {
        option.dropdown_field_name = dropdownField.field_name;
      });
    });
    return;
  }

  forceCategory(category: DropdownField, state: boolean): void {
    if (state) {
      if (!this.categorySelected(category.name)) {
        this.selectedCategories.push(category);
      }
    } else {
      if (this.categorySelected(category.name)) {
        this.selectedCategories = this.selectedCategories.filter(
          (cat) => cat.name !== category.name
        );
      }
    }
    this.filterResults();
  }

  toggleCategory(category: DropdownField): void {
    if (this.categorySelected(category.name)) {
      this.selectedCategories = this.selectedCategories.filter(
        (cat) => cat.name !== category.name
      );
    } else {
      this.selectedCategories.push(category);
    }
    this.filterResults();
  }

  categorySelected(categoryName: string): boolean {
    return this.selectedCategories.find(
      (category) => category.name === categoryName
    );
  }

  currentOfferIndex(offer: Offer): number {
    return this.filteredOffers.findIndex(
      (otherOffer) => otherOffer.id === offer.id
    );
  }

  filterResults(): void {
    let filteredOffersTemp: Offer[] = this.offers;
    if (this.searchTerm !== '') {
      this.searchTerms = this.searchTerm.toLowerCase().trim().split(' ').map(item => stem(item));

      this.searchTerms.forEach(term => {
        filteredOffersTemp = filteredOffersTemp.filter(offer =>  {
          return this.checkInside(offer, term);
        });
      });
    }

    if (
      this.selectedCategories.length === 0 &&
      this.selectedOptions.length === 0
    ) {
      this.filteredOffers = filteredOffersTemp;
      return;
    }

    if (this.selectedCategories.length === 0) {
      this.filteredOffers = filteredOffersTemp.filter((offer) => {
        return this.selectedOption(offer);
      });
      return;
    }

    this.filteredOffers = filteredOffersTemp.filter((offer) => {
      return (
        this.selectedCategories
          .map((cat) => cat.name)
          .includes(offer.offer_type_name) && this.selectedOption(offer)
      );
    });
  }

  selectedOption(offer: Offer): boolean {
    // If no option is selected all options are valid
    if (this.selectedOptions.length === 0) {
      return true;
    }
    const fieldNames = [
      ...new Set(
        this.selectedOptions.map((option) => option.dropdown_field_name)
      )
    ];
    const result = fieldNames.map((fieldName) => {
      return this.selectedOptions.find((option) => {
        return offer[fieldName].includes(option.name);
      });
    });
    return !result.includes(undefined);
  }

  updateSelectedOptions(): DropdownOption[] {
    const results = [];
    this.dropdownFields.forEach((dropdown) => {
      dropdown.dropdown_options.forEach((option) => {
        if (option.selected) {
          results.push(option);
        }
      });
    });
    this.selectedOptions = results;
    this.filterResults();
    return this.selectedOptions;
  }

  dropdownOptionAvailable(dropdown: DropdownOption): boolean {
    if (this.selectedCategories.length === 0) {
      return this.standardFilters.includes(dropdown.name);
    }
    const dropdowns = this.selectedCategories.map((selectedCategory) => {
      return selectedCategory.dropdown_fields.map((field) => field.name);
    });
    const result = dropdowns.map((dropdownItem) => {
      return dropdownItem.includes(dropdown.name);
    });
    return !result.includes(false);
  }

  dropdownOptionHasOffers(option): boolean {
    const result = this.filteredOffers.find((offer) =>
      offer[option.dropdown_field_name].includes(option.name)
    );
    return Boolean(result);
  }

  private checkInside(item: any, term: string): boolean {
    for (const property in item) {
      if (item[property] === null || item[property] === undefined || this.searchExcludesAttributes.includes(property)) {
        continue;
      }
      if (typeof item[property] === 'object') {
        if (this.checkInside(item[property], term)) {
          return true;
        }
      }
      else if (stem(item[property].toString().toLowerCase()).includes(term)) {
        return true;
      }
    }
    return false;
  }
}
