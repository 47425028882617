<section class="container">
  <div class="filters">
    <h2>{{ 'HOME.FILTER-TITLE' | translate }}</h2>
    <div class="row">
      <input
      type="text"
      [formControl]="searchTermControl"
      class="form-control search-box"
      placeholder="{{ 'HOME.SEARCH_PLACEHOLDER' | translate }}">
    </div>
    <div class="row">
      <ng-container
        *ngFor="
          let cat of dataService.offerTypes | slice: 0:4;
          index as categoryIndex
        "
      >
        <!-- only work on mobile -->
        <div class="filter-dropdown mb-3 col-12 px-0">
          <button
            type="button"
            class="btn dropdown-toggle filters__item--main {{ utilsService.categoryColorForDepartment(cat.name) }}"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            [disabled]="disabled"
          >
            <span>{{ cat.name }}</span>
          </button>
          <ul class="dropdown-menu dropdown-list filters__item--menu">
            <ng-container *ngFor="let subcat of cat.offer_types; index as offerIndex">
              <li class="list-group-item filters__item--sub">
                <div class="custom-control custom-checkbox">
                  <input
                    [checked]="dataService.categorySelected(subcat.name)"
                    (change)="dataService.toggleCategory(subcat)"
                    type="checkbox"
                    class="filters__toggle custom-control-input"
                    id="subcat-{{ categoryIndex }}{{ offerIndex }}"
                  />
                  <label
                    class="custom-control-label {{ utilsService.categoryColorForDepartment(cat.name) }}"
                    for="subcat-{{ categoryIndex }}{{ offerIndex }}"
                    >{{ subcat.name }}</label
                  >
                </div>
              </li>
            </ng-container>
          </ul>
        </div>
      </ng-container>
    </div>

    <div routerLink="/filters" class="row d-flex justify-content-between filters__loadmore">
      <div>{{ 'HOME.MORE-FILTERS' | translate }}</div>
      <img src="../../../assets/images/expand-right-gray.svg">
    </div>
  </div>

  <div class="row my-2">
    <div class="col-auto">
      {{ 'HOME.OFFERS_COUNT_LABEL' | translate }}:&nbsp;<b>{{
        (dataService.filteredOffers).length
      }}</b>
    </div>
  </div>
</section>
