import { Component, OnInit } from '@angular/core';
import { FiltersComponent } from '../filters/filters.component';

@Component({
  selector: 'app-filters-mobile',
  templateUrl: './filters-mobile.component.html',
  styleUrls: ['./filters-mobile.component.scss']
})
export class FiltersMobileComponent extends FiltersComponent implements OnInit {
  // functionality is same as filterscomponent, just HTML is differnt
}
