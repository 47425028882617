import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CONFIG } from 'src/app/config/config';
import {
  SortingService,
  SortingConfig
} from 'src/app/services/sorting.service';

@Component({
  selector: 'app-offers-list-sort',
  templateUrl: './offers-list-sort.component.html',
  styleUrls: ['./offers-list-sort.component.scss']
})
export class OffersListSortComponent implements OnInit {
  @Output() sort: EventEmitter<any> = new EventEmitter();

  ALLOWED_FIELDS: string[] = CONFIG.OFFERS_LIST.ALLOWED_COLUMNS;
  SORTABLE_FIELDS: string[] = CONFIG.OFFERS_LIST.SORTABLE_COLUMNS;
  COL_WIDTHS: string[] = CONFIG.OFFERS_LIST.COL_WIDTHS;

  sorting: { [key: string]: SortingConfig } = {};

  constructor(private sortingService: SortingService) {}

  ngOnInit(): void {
    const initialSorting = this.sortingService.getActiveSorting();

    this.SORTABLE_FIELDS.map((field) => {
      if (initialSorting && field === initialSorting.field) {
        this.sorting[field] = initialSorting;
      } else {
        this.sorting[field] = { field, order: null };
      }
    });
  }

  isSortable(field: string): boolean {
    return !!this.sorting[field];
  }

  onSort(field: string): void {
    // Alternate between ascending and descending sorting
    this.sorting[field].order =
      this.sorting[field].order === 'DESC' ? 'ASC' : 'DESC';

    // Reset all other sort fields
    Object.keys(this.sorting).forEach((key, index) => {
      if (key !== field) {
        this.sorting[key].order = null;
      }
    });

    this.sort.emit(this.sorting[field]);
  }
}
