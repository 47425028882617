import { DropdownField, OfferType } from './../../services/data.service';
import { Component, OnInit } from '@angular/core';
import {
  AdminService,
  EditableOffer,
  OfferSavingTechnique
} from '../../services/admin.service';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ErrorMessage, DataService } from 'src/app/services/data.service';
import { UtilService } from 'src/app/services/util.service';
import { Observable } from 'rxjs';
import { CONFIG } from '../../config/config';
import Municipality from 'src/app/definitions/Municipality.interface';

@Component({
  selector: 'app-offer-edit',
  templateUrl: './offer-edit.component.html',
  styleUrls: ['./offer-edit.component.scss']
})
export class OfferEditComponent implements OnInit {
  offer: EditableOffer;
  uid: string;
  offerFormGroup: FormGroup;
  backendErrors: ErrorMessage;
  success: boolean;
  showErrors = false;
  dropDowns: DropdownField[];
  offerType: OfferType;
  loading = true;
  published = false;
  municipalitiesList: Municipality[] = [];

  // offer pricings
  OFFER_DETAIL_ACCORDION_FIELDS: string[] =
    CONFIG.OFFER_DETAIL_ACCORDION.ALLOWED_COLUMNS;
  OFFER_DETAIL_ACCORDION_FIELDS_OTHER: string[] =
    CONFIG.OFFER_DETAIL_ACCORDION.ALLOWED_COLUMNS_OTHER;
  OFFER_DETAIL_ACCORDION_COL_WIDTHS: string[] =
    CONFIG.OFFER_DETAIL_ACCORDION.COL_WIDTHS;

  constructor(
    private adminService: AdminService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private dataService: DataService
  ) {}

  async ngOnInit(): Promise<void> {
    this.uid = this.route.snapshot.paramMap.get('uid');
    this.adminService.getEdittableOffer(this.uid).subscribe(
      async (offer) => {
        this.offer = offer;
        const offerTypes = await this.dataService.getOfferTypes();
        this.municipalitiesList = await this.dataService.getMunicipalities();
        this.offerType = offerTypes
          .find((department) => {
            return department.name === offer.department_name;
          })
          .offer_types.find(
            (offerType) => offerType.name === offer.offer_type_name
          );
        this.initForm();
        this.loading = false;
      },
      (error) => {
        console.log(error);
        this.loading = false;
      }
    );
    this.dropDowns = await this.dataService.getDropdownFields();
  }

  private initForm(): void {
    this.offerFormGroup = this.formBuilder.group({
      ...this.staticFields(),
      ...this.dynamicFields()
    });
  }

  private submit(
    offerSavingTechnique: OfferSavingTechnique
  ): Observable<EditableOffer> {
    this.success = false;
    this.backendErrors = null;
    this.offerFormGroup.markAllAsTouched();
    if (this.offerFormGroup.invalid) {
      this.showErrors = true;
      return;
    }
    this.offer = this.offerFormGroup.value;
    return this.adminService.updateEdittableOffer(
      this.offer,
      this.uid,
      offerSavingTechnique
    );
  }

  update(): void {
    this.offerFormGroup.markAllAsTouched();
    if (this.offerFormGroup.invalid) {
      this.showErrors = true;
      return;
    }
    this.submit(OfferSavingTechnique.OnlyUpdate).subscribe(
      (offer) => {
        this.success = true;
        this.showErrors = false;
        this.offer = offer;
        window.scrollTo(0, 0);
      },
      (error) => {
        this.showErrors = true;
        this.backendErrors = error.error as ErrorMessage;
        window.scrollTo(0, 0);
      }
    );
  }

  updateAndPreview(): void {
    this.offerFormGroup.markAllAsTouched();
    if (this.offerFormGroup.invalid) {
      this.showErrors = true;
      return;
    }
    this.submit(OfferSavingTechnique.OnlyUpdate).subscribe(
      (offer) => {
        this.success = true;
        this.showErrors = false;
        this.offer = offer;
        window.open(`/offer/${this.uid}/preview`);
      },
      (error) => {
        this.showErrors = true;
        this.backendErrors = error.error as ErrorMessage;
        window.scrollTo(0, 0);
      }
    );
  }

  publish(): void {
    this.submit(OfferSavingTechnique.UpdateAndPublish).subscribe(
      (offer) => {
        this.success = true;
        this.published = true;
        this.showErrors = false;
        this.offer = offer;
        window.scrollTo(0, 0);
      },
      (error) => {
        this.showErrors = true;
        this.backendErrors = error.error as ErrorMessage;
        window.scrollTo(0, 0);
      }
    );
  }

  hasError(attribute: string): boolean {
    return this.utilService.hasError(attribute, this.offerFormGroup);
  }

  errorFor(attribute: string): string {
    return this.utilService.errorFor(attribute, this.offerFormGroup);
  }

  // fields that are always present
  staticFields(): any {
    const URL_REGEX = 'https?://.*';
    return {
      title: [this.offer.title],
      municipalities: [this.offer.municipalities.map(item => item.id)],
      short_description: [this.offer.short_description],
      contact_email: [this.offer.contact_email],
      cancellation_policy: [
        { value: this.offer.cancellation_policy, disabled: false }
      ],
      // registration: [this.offer.registration, Validators.pattern(URL_REGEX)],
      phone: [this.offer.phone],
      external_url: [this.offer.external_url, Validators.pattern(URL_REGEX)],
      external_url_2: [
        this.offer.external_url_2,
        Validators.pattern(URL_REGEX)
      ],
      duration_detail: [this.offer.duration_detail],
      place_detail: [this.offer.place_detail],
      offer_type: [{ value: this.offer.offer_type_name, disabled: true }],
      intensity: [{ value: this.offer.intensity, disabled: true }],
      // intensity_short: [this.offer.intensity_short],
      external_offer_url: [
        this.offer.external_offer_url,
        Validators.pattern(URL_REGEX)
      ],
      external_offer_url_2: [
        this.offer.external_offer_url_2,
        Validators.pattern(URL_REGEX)
      ],
      entry_time: [this.offer.entry_time],
      internal_reference: [
        { value: this.offer.internal_reference, disabled: true }
      ],
      author_email: [this.offer.author_email],
      contact_person: [this.offer.contact_person]
    };
  }

  // fields that come from the API / not all are present
  // tslint:disable: no-string-literal
  dynamicFields(): any {
    const fields = {};
    if (this.getDropDownByName('region')) {
      fields['region'] = [this.offer.region];
    }
    if (this.getDropDownByName('place')) {
      fields['place'] = [this.offer.place];
    }
    if (this.getDropDownByName('institution')) {
      fields['institution'] = [
        { value: this.offer.institution, disabled: true }
      ];
    }
    if (this.getDropDownByName('target_group')) {
      fields['target_group'] = [this.offer.target_group];
    }
    if (this.getDropDownByName('course_level')) {
      fields['course_level'] = [this.offer.course_level];
    }
    if (this.getDropDownByName('child_care')) {
      fields['child_care'] = [this.offer.child_care];
    }
    if (this.getDropDownByName('course_time')) {
      fields['course_time'] = [this.offer.course_time];
    }
    if (this.getDropDownByName('course_content')) {
      fields['course_content'] = [this.offer.course_content];
    }
    if (this.getDropDownByName('course_topic')) {
      fields['course_topic'] = [this.offer.course_topic];
    }
    if (this.getDropDownByName('workload')) {
      fields['workload'] = [this.offer.workload];
    }
    if (this.getDropDownByName('integration_goal')) {
      fields['integration_goal'] = [this.offer.integration_goal];
    }

    if (this.getDropDownByName('german_level_starting')) {
      fields['german_level_starting'] = [this.offer.german_level_starting];
    }

    if (this.getDropDownByName('work_assignment')) {
      fields['work_assignment'] = [this.offer.work_assignment];
    }

    if (this.getDropDownByName('industry')) {
      fields['industry'] = [this.offer.industry];
    }

    if (this.getDropDownByName('modules')) {
      fields['modules'] = [this.offer.modules];
    }
    if (this.getDropDownByName('intensity_filter')) {
      fields['intensity_filter'] = [this.offer.intensity_filter];
    }

    return fields;
  }
  // tslint:enable: no-string-literal

  getDropDownByName(name: string): DropdownField {
    if (this.offerType) {
      return this.offerType.dropdown_fields.find(
        (dropdown) => dropdown.field_name === name
      );
    }
  }
}
