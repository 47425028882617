<ng-container [ngSwitch]="view">
  <ng-container *ngSwitchCase="'home'">
    <section class="container-fluid bg-turquoise">
      <a id="contact"></a>
      <div class="container py-5">
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col">
                <h2 class="mb-4 title-top-border">
                  {{ 'CONTACT.TITLE' | translate }}
                </h2>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <h3 class="mb-4">
                  {{ 'CONTACT.SUBTITLE' | translate }}
                </h3>
              </div>
            </div>

            <div class="row contact-links">
              <div class="col-sm-4 contact-links__item">
                <div class="contact-links__icon d-none d-sm-flex">
                  <img src="../../../assets/icons/location.svg" />
                </div>
                <p>
                  <strong>
                    <span>
                      {{ 'CONTACT.ADDRESS_LINE_1' | translate }}
                      <br />
                      {{ 'CONTACT.ADDRESS_LINE_2' | translate }}
                    </span>
                  </strong>
                </p>
                <!-- <p>
                  <span>
                    <a
                      [href]="'CONTACT.LOCATION_LINK.LINK' | translate"
                      target="_blank"
                    >
                      {{ 'CONTACT.LOCATION_LINK.TITLE' | translate }}
                    </a>
                  </span>
                </p> -->
              </div>
              <div class="col-sm-4 contact-links__item">
                <div class="contact-links__icon d-none d-sm-flex">
                  <img src="../../../assets/icons/phone-call.svg" />
                </div>
                <p>
                  <strong class="text-underline">
                    <a href="tel:{{ 'CONTACT.PHONE' | translate }}">
                      <span>{{ 'CONTACT.PHONE' | translate }}</span>
                    </a>
                  </strong>
                </p>
                <p class="d-none d-sm-block address-block">
                  <strong>
                    <span>{{
                      'CONTACT.OFFICE_HOURS_LABEL' | translate
                    }}</span></strong
                  >
                  <br />
                  {{ 'CONTACT.OFFICE_HOURS_1' | translate }}
                  <br />
                  {{ 'CONTACT.OFFICE_HOURS_2' | translate }}
                </p>
              </div>
              <div class="col-sm-4 contact-links__item">
                <div class="contact-links__icon d-none d-sm-flex">
                  <img src="../../../assets/icons/email.svg" />
                </div>
                <strong class="text-underline">
                  <a href="mailto:{{ 'CONTACT.EMAIL' | translate }}">
                    <span>{{ 'CONTACT.EMAIL' | translate }}</span>
                  </a>
                </strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </ng-container>
</ng-container>

<footer class="container-fluid d-none d-sm-block bg-{{ color }}">
  <div class="container py-5">
    <div class="row align-items-end">
      <div class="col-9">
        <!-- <h2>{{ 'FOOTER.TITLE' | translate }}</h2>
        <ul class="footer-menu">
          <li *ngFor="let menuLink of ('FOOTER.MENU_LINKS' | translate)">
            <a [href]="menuLink.LINK">{{ menuLink.TITLE }}</a>
          </li>
        </ul> -->
        <ul>
          <li>
            <span>{{ 'FOOTER.COPYRIGHT' | translate }}</span>
          </li>
          <li>
            <a [href]="'FOOTER.TERMS_OF_USE.LINK' | translate">{{
              'FOOTER.TERMS_OF_USE.TITLE' | translate
            }}</a>
          </li>
        </ul>
      </div>
      <div class="col-3 col-right-align">
        <!-- <div class="social-title">
          {{ 'FOOTER.SOCIAL_LINKS_TITLE' | translate }}
        </div> -->
        <!-- <app-footer-social-menu></app-footer-social-menu> -->
      </div>
    </div>
  </div>
</footer>

<!-- for mobile -->
<footer class="container-fluid d-flex d-sm-none bg-{{ color }}">
  <div class="container py-3">
    <div class="row">
      <div>
        <!-- <h2 class="mb-0">{{ 'FOOTER.TITLE' | translate }}</h2>
        <ul class="footer-menu">
          <li *ngFor="let menuLink of 'FOOTER.MENU_LINKS' | translate">
            <a class="font-weight-normal" [href]="menuLink.LINK">{{
              menuLink.TITLE
            }}</a>
          </li>
        </ul> -->
        <!-- <app-footer-social-menu></app-footer-social-menu> -->
        <ul class="mt-3">
          <li>
            <span>{{ 'FOOTER.COPYRIGHT' | translate }}</span>
          </li>
          <li>
            <a [href]="'FOOTER.TERMS_OF_USE.LINK' | translate">{{
              'FOOTER.TERMS_OF_USE.TITLE' | translate
            }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</footer>
