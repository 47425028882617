import { Injectable } from '@angular/core';
import Offer from '../definitions/Offer.interface';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup } from '@angular/forms';
import { Departments } from './data.service';
import { TooltipComponent } from '../components/tooltip/tooltip.component';

@Injectable({
  providedIn: 'root'
})
export class UtilService {
  activeTooltip: TooltipComponent;

  constructor(private translationService: TranslateService) {}

  categoryColorForDepartment(departmentName: string): string {
    return {
      // tslint:disable:object-literal-key-quotes
      Kompetenzerfassung: 'cat-0',
      Abklärung: 'cat-0',
      Sprache: 'cat-1',
      Bildung: 'cat-2',
      Arbeitsintegration: 'cat-3'
      // tslint:enable:object-literal-key-quotes
    }[departmentName];
  }

  hasError(attribute: string, formGroup: FormGroup): boolean {
    if (formGroup.get(attribute).touched && formGroup.get(attribute).invalid) {
      return true;
    }

    return false;
  }

  errorFor(attribute: string, formGroup: FormGroup): string {
    const errorTypes = Object.keys(formGroup.get(attribute).errors);
    if (errorTypes.length) {
      const firstError = errorTypes[0].toLocaleUpperCase();
      return this.translationService.instant(`ERRORS.${firstError}`);
    }
  }

  translateAttribute(model: string, field: string): string {
    return this.translationService.instant(
      `${model.toUpperCase()}.FIELDS.${field.toLocaleUpperCase()}`
    );
  }

  getOfferDetailSlug(offer: Offer): string {
    // TODO change or keep slug format, what is appropriate
    return `${offer.id}-${encodeURIComponent(
      offer.title.split(' ').join('-').toLowerCase()
    )}`;
  }

  getIdFromSlug(slug: string): string {
    return slug.split('-')[0];
  }

  orderDepartments(departments: Departments[]): Departments[] {
    return departments.sort((a, b) => a.order_index - b.order_index);
  }

  offerRoute(offer: Offer): string[] {
    const slug = this.getOfferDetailSlug(offer);

    return ['/detail', slug];
  }
}
