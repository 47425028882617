import { DataService } from './../../services/data.service';
import { Component, OnInit, Input } from '@angular/core';
import { DropdownOption } from '../../services/data.service';

@Component({
  selector: 'app-filter-selection',
  templateUrl: './filter-selection.component.html',
  styleUrls: ['./filter-selection.component.scss']
})
export class FilterSelectionComponent implements OnInit {
  @Input() selected: DropdownOption[];
  @Input() readOnly = false;

  constructor(private dataService: DataService) {}

  ngOnInit(): void {}

  remove(item: DropdownOption): void {
    item.selected = false;
    this.dataService.updateSelectedOptions();
  }

  clear(): void {
    let item: DropdownOption;
    for (item of this.selected ) {
      item.selected = false;
    }
    this.dataService.updateSelectedOptions();
  }
}
