import { Component } from '@angular/core';
import { AdminService } from '../../services/admin.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { UtilService } from 'src/app/services/util.service';
import { Meta, Title } from '@angular/platform-browser';
import { OfferDetailComponent } from '../offer-detail/offer-detail.component';
import { MatomoTracker } from 'ngx-matomo';

@Component({
  selector: 'app-offer-preview',
  templateUrl: '../offer-detail/offer-detail.component.html',
  styleUrls: ['../offer-detail/offer-detail.component.scss']
})
export class OfferPreviewComponent extends OfferDetailComponent {
  isPreview = true;

  constructor(
    protected dataService: DataService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected matomoTracker: MatomoTracker,
    public utilService: UtilService,
    public meta: Meta,
    public title: Title,
    private adminService: AdminService
  ) {
    super(dataService, route, router, matomoTracker, utilService, meta, title);
  }

  async fetchOffer(): Promise<void> {
    const uid = this.route.snapshot.paramMap.get('uid');
    this.offer = await this.adminService.getPreviewOfOffer(uid);
  }
}
