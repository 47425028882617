import { Component, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnDestroy {

  constructor() {
    document.body.className = 'bg-white';
  }

  ngOnDestroy(): void {
    document.body.className = '';
  }

}
