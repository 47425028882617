import { OfferDetailComponent } from './pages/offer-detail/offer-detail.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { StlyeguideComponent } from './components/stlyeguide/stlyeguide.component';
import { HomeComponent } from './pages/home/home.component';
import { OfferEditComponent } from './pages/offer-edit/offer-edit.component';
import { MaintenanceComponent } from './pages/maintenance/maintenance.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { ErrorComponent } from './pages/error/error.component';
import { OfferBookingComponent } from './pages/offer-booking/offer-booking.component';
import { MoreFiltersMobileComponent } from './pages/more-filters-mobile/more-filters-mobile.component';
import { FilterDropdownOptionsMobileComponent } from './pages/filter-dropdown-options-mobile/filter-dropdown-options-mobile.component';
import { OfferPreviewComponent } from './pages/offer-preview/offer-preview.component';

const routes: Routes = [
  { path: 'styleguide', component: StlyeguideComponent },
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  { path: 'home', component: HomeComponent },
  { path: 'filters', component: MoreFiltersMobileComponent },
  {
    path: 'filters/:dropdownSlug',
    component: FilterDropdownOptionsMobileComponent
  },
  {
    path: 'detail/:offerSlug',
    component: OfferDetailComponent
  },
  {
    path: 'offer/:uid/edit',
    component: OfferEditComponent
  },
  {
    path: 'maintenance',
    component: MaintenanceComponent
  },
  {
    path: 'booking/:offerSlug',
    component: OfferBookingComponent
  },
  { path: 'offer/:uid/edit', component: OfferEditComponent },
  { path: 'offer/:uid/preview', component: OfferPreviewComponent },
  { path: 'maintenance', component: MaintenanceComponent },
  { path: '404', component: NotFoundComponent },
  { path: 'error', component: ErrorComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'top',
    anchorScrolling: 'enabled',
    relativeLinkResolution: 'legacy'
})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
