export const CONFIG = {
  OFFERS_LIST: {
    // This array configures which fields are displayed in offers-list-item
    // and offers-list-sort. Fields name must be same as of Offer interface
    // and order is maintained by position in this array.
    ALLOWED_COLUMNS: [
      'offer_type_name',
      'institution',
      'title',
      'internal_reference',
      'municipalities_short',
      'price_formatted'
    ],
    SORTABLE_COLUMNS: [
      'offer_type_name',
      'institution',
      'title',
      'internal_reference',
      'municipalities_short',
      'duration_detail',
      'intensity_short'
    ],
    // in case we have a helper attribute that we display on the view but we cant use it to sort
    SORTABILITY_MAPPING: {
      price_formatted: 'price'
    },
    // How columns should be responsive. It applies for columns on same
    // index in array above. Values are supposed to be BS grid classes.
    // there needs to be one col left (so sum needs to be 11), since
    // at the end there is also a column with "show detial" link
    COL_WIDTHS: ['col-2', 'col-2', 'col-3', 'col-2', 'col-2', 'col-1']
  },
  OFFER_DETAIL_ACCORDION: {
    ALLOWED_COLUMNS: ['description', 'duration', 'unit', 'price'],
    ALLOWED_COLUMNS_OTHER: ['description_other', 'duration', 'unit', 'price'],
    COL_WIDTHS: ['col-5', 'col-3', 'col-2', 'col-2']
  }
};
