<app-page-header>
  <div class="back-btn d-flex align-items-center" (click)="goBack()">
    <img class="mr-1" src="../../../assets/images/back.svg">
    {{ "BACK_BUTTON" | translate }}
  </div>
</app-page-header>

<app-page-content>
  <ng-container *ngFor="let dropdown of dataService.dropdownFields">
    <div
      *ngIf="dataService.dropdownOptionAvailable(dropdown)"
      class="container-fluid"
    >
      <app-filter-dropdown
        [label]="dropdown.name_short || dropdown.name"
        [options]="dropdown.dropdown_options"
        [dropdownSlug]="dropdown.field_name"
        (selected)="filterDropdownSelected(event)"
      ></app-filter-dropdown>
    </div>
  </ng-container>
</app-page-content>