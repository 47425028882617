import { Component, Input, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import {
  SortingService,
  SortingConfig
} from 'src/app/services/sorting.service';
import { CONFIG } from 'src/app/config/config';

@Component({
  selector: 'app-offers-list',
  templateUrl: './offers-list.component.html',
  styleUrls: ['./offers-list.component.scss']
})
export class OffersListComponent implements OnInit {
  isLoading = false;
  SORTABILITY_MAPPING: any = CONFIG.OFFERS_LIST.SORTABILITY_MAPPING;

  constructor(
    public dataService: DataService,
    private sortingService: SortingService
  ) {}

  async ngOnInit(): Promise<void> {
    try {
      this.isLoading = true;
      if (this.dataService.offers.length === 0) {
        await this.dataService.updateOffers();
      }
      this.isLoading = false;
    } catch (e) {
      console.log(e);
      this.isLoading = false;
    }
  }

  sort(sortBy: SortingConfig = null): void {
    // if the field maps to other field (eg. field is serving as an helper)
    // example formatted_price => price as we want numeric comparsion
    if (this.SORTABILITY_MAPPING[sortBy.field]) {
      sortBy.field = this.SORTABILITY_MAPPING[sortBy.field];
    }

    this.sortingService.sort(this.dataService.filteredOffers, sortBy);
  }
}
