<app-page-header color="turquoise" id="header">
  <a id="skip-nav" class="screenreader-text mr-3" href="/home#main-content">
    Skip to Content
  </a>
  <a id="skip-nav" class="screenreader-text" href="/home#contact">
    Skip to Footer
  </a>
  <app-top-bar></app-top-bar>
  <app-hero></app-hero>
</app-page-header>

<app-page-content>
  <div class="container">
    <app-filters class="d-none d-sm-block"></app-filters>
    <app-filters-mobile class="d-block d-sm-none"></app-filters-mobile>
  </div>
  <div class="container-fluid" id="main-content">
    <app-offers-list></app-offers-list>
  </div>
  <div class="container">
    <section class="container bg-white py-4 px-4 my-5">
      <h2>{{ 'LINKS.TITLE' | translate }}</h2>
      <p class="mb-5">
        {{ 'LINKS.TEXT' | translate }}
      </p>
      <div>
        <app-links-list
          newWindow="true"
          color="turquoise"
          [links]="'LINKS.LINKS' | translate"
        ></app-links-list>
      </div>
    </section>
  </div>
  <div class="screenreader-text">
    <a id="skip-nav" class="text-blue" href="/home#header"> Skip to Header </a>
  </div>
</app-page-content>

<app-footer view="home"></app-footer>
