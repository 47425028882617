<ng-container *ngIf="offer; else offerNotFoundTemplate">
  <app-page-content>
    <div class="container bg-white">
      <div class="form-wrapper mt-4">
        <app-alert
          [errors]="backendErrors.errors"
          class="danger mt-3 w-100"
          *ngIf="backendErrors"
          model="OFFER"
        >
        </app-alert>
        <app-alert
          [message]="'ALERT.SUCCESS' | translate"
          class="success mt-3 w-100"
          *ngIf="success"
        ></app-alert>
        <app-alert
          [message]="'ALERT.READY_FOR_PUBLISHING' | translate"
          class="blue mt-3 w-100"
          *ngIf="success && offer.state == 'ready_for_publishing'"
        ></app-alert>
        <h2 class="mt-5" *ngIf="!published">
          {{ 'ADMIN.EDIT_OFFER' | translate }}
        </h2>
        <div class="container-fluid">
          <p
            *ngIf="published"
            class="mt-5 lead text-wrap-pre-wrap"
            [innerHtml]="'ADMIN.INFO_PUBLISHED' | translate"
          ></p>
          <p
            *ngIf="!published"
            class="text-wrap-pre-wrap mt-5"
            [innerHtml]="'ADMIN.DESCRIPTION' | translate"
          ></p>
        </div>
        <form
          class="form container-fluid"
          [formGroup]="offerFormGroup"
          *ngIf="offerFormGroup"
        >
          <div class="form-group">
            <label for="offer_type"
              >{{ 'OFFERS.FIELDS.OFFER_TYPE_NAME' | translate }}
            </label>
            <input
              class="form-control {{ hasError('offer_type') && 'is-invalid' }}"
              name="offer_type"
              type="text"
              formControlName="offer_type"
            />
            <label class="field-description">
              {{ 'OFFERS.DESCRIPTIONS.OFFER_TYPE_NAME' | translate }}</label
            >
          </div>

          <div class="form-group" *ngIf="getDropDownByName('region')">
            <label for="region">{{ 'OFFERS.FIELDS.REGION' | translate }}</label>
            <select
              multiple
              class="custom-select {{ hasError('region') && 'is-invalid' }}"
              (change)="changeDropdownOption($event)"
              formControlName="region"
            >
              <option
                *ngFor="
                  let option of getDropDownByName('region')?.dropdown_options
                "
                [ngValue]="option.name"
              >
                {{ option.name }}
              </option>
            </select>
            <label class="field-description">
              {{ 'OFFERS.DESCRIPTIONS.REGION' | translate }}</label
            >
            <label class="text-danger" *ngIf="showErrors && hasError('region')">
              {{ errorFor('region') }}
            </label>
          </div>

          <div class="form-group">
            <label for="region">{{ 'OFFERS.FIELDS.MUNICIPALITIES' | translate }}</label>
            <ng-select [multiple]="true" formControlName="municipalities">
              <ng-option *ngFor="let item of municipalitiesList" [value]="item.id">{{item.city}}</ng-option>
            </ng-select>
            <label class="field-description">
              {{ 'OFFERS.DESCRIPTIONS.MUNICIPALITIES' | translate }}</label
            >
            <label class="text-danger" *ngIf="showErrors && hasError('municipalities')">
              {{ errorFor('municipalities') }}
            </label>
          </div>

          <div class="form-group" *ngIf="getDropDownByName('institution')">
            <label for="institution">{{
              'OFFERS.FIELDS.INSTITUTION' | translate
            }}</label>
            <input
              class="form-control {{ hasError('institution') && 'is-invalid' }}"
              name="institution"
              type="text"
              formControlName="institution"
            />
            <label class="field-description">
              {{ 'OFFERS.DESCRIPTIONS.INSTITUTION' | translate }}</label
            >
            <label
              class="text-danger"
              *ngIf="showErrors && hasError('institution')"
            >
              {{ errorFor('institution') }}
            </label>
          </div>

          <div class="form-group">
            <label for="title">{{ 'OFFERS.FIELDS.TITLE' | translate }} </label>
            <input
              class="form-control {{ hasError('title') && 'is-invalid' }}"
              name="title"
              type="text"
              formControlName="title"
            />
            <label class="field-description">
              {{ 'OFFERS.DESCRIPTIONS.TITLE' | translate }}</label
            >
            <label class="text-danger" *ngIf="showErrors && hasError('title')">
              {{ errorFor('title') }}
            </label>
          </div>

          <div class="form-group">
            <label for="short_description"
              >{{ 'OFFERS.FIELDS.SHORT_DESCRIPTION' | translate }}
            </label>
            <textarea
              class="form-control {{
                hasError('short_description') && 'is-invalid'
              }}}"
              name="short_description"
              formControlName="short_description"
              placeholder="{{
                'OFFERS.FIELDS.DESCRIPTION_PLACEHOLDER' | translate
              }}"
              rows="5"
              maxlength="800"
            ></textarea>
            <label class="field-description">
              {{ 'OFFERS.DESCRIPTIONS.SHORT_DESCRIPTION' | translate }}</label
            >
            <label
              class="text-danger"
              *ngIf="showErrors && hasError('short_description')"
            >
              {{ errorFor('short_description') }}
            </label>
          </div>

          <div class="form-group" *ngIf="getDropDownByName('place')">
            <label for="place">{{ 'OFFERS.FIELDS.PLACE' | translate }}</label>
            <select
              multiple
              class="custom-select {{ hasError('place') && 'is-invalid' }}"
              (change)="changeDropdownOption($event)"
              formControlName="place"
            >
              <option
                *ngFor="
                  let option of getDropDownByName('place')?.dropdown_options
                "
                [ngValue]="option.name"
              >
                {{ option.name }}
              </option>
            </select>
            <label class="field-description">
              {{ 'OFFERS.DESCRIPTIONS.PLACE' | translate }}</label
            >
            <label class="text-danger" *ngIf="showErrors && hasError('place')">
              {{ errorFor('place') }}
            </label>
          </div>

          <div class="form-group">
            <label for="place_detail"
              >{{ 'OFFERS.FIELDS.PLACE_DETAIL' | translate }}
            </label>
            <textarea
              class="form-control {{
                hasError('place_detail') && 'is-invalid'
              }}"
              name="place_detail"
              formControlName="place_detail"
              rows="2"
            ></textarea>
            <label class="field-description">
              {{ 'OFFERS.DESCRIPTIONS.PLACE_DETAIL' | translate }}</label
            >
            <label
              class="text-danger"
              *ngIf="showErrors && hasError('place_detail')"
            >
              {{ errorFor('place_detail') }}
            </label>
          </div>

          <div class="form-group" *ngIf="getDropDownByName('target_group')">
            <label for="target_group">{{
              'OFFERS.FIELDS.TARGET_GROUP' | translate
            }}</label>
            <select
              multiple
              class="custom-select {{
                hasError('target_group') && 'is-invalid'
              }}"
              (change)="changeDropdownOption($event)"
              formControlName="target_group"
            >
              <option
                *ngFor="
                  let option of getDropDownByName('target_group')
                    ?.dropdown_options
                "
                [ngValue]="option.name"
              >
                {{ option.name }}
              </option>
            </select>
            <label class="field-description">
              {{ 'OFFERS.DESCRIPTIONS.TARGET_GROUP' | translate }}</label
            >
            <label
              class="text-danger"
              *ngIf="showErrors && hasError('target_group')"
            >
              {{ errorFor('target_group') }}
            </label>
          </div>

          <div class="form-group" *ngIf="getDropDownByName('course_level')">
            <label for="course_level">{{
              'OFFERS.FIELDS.COURSE_LEVEL' | translate
            }}</label>
            <select
              multiple
              class="custom-select {{
                hasError('course_level') && 'is-invalid'
              }}"
              (change)="changeDropdownOption($event)"
              formControlName="course_level"
            >
              <option
                *ngFor="
                  let option of getDropDownByName('course_level')
                    ?.dropdown_options
                "
                [ngValue]="option.name"
              >
                {{ option.name }}
              </option>
            </select>
            <label class="field-description">
              {{ 'OFFERS.DESCRIPTIONS.COURSE_LEVEL' | translate }}</label
            >
            <label
              class="text-danger"
              *ngIf="showErrors && hasError('course_level')"
            >
              {{ errorFor('course_level') }}
            </label>
          </div>

          <div class="form-group" *ngIf="getDropDownByName('intensity_filter')">
            <label for="intensity_filter">{{
              'OFFERS.FIELDS.INTENSITY_FILTER' | translate
            }}</label>
            <select
              multiple
              class="custom-select {{
                hasError('intensity_filter') && 'is-invalid'
              }}"
              (change)="changeDropdownOption($event)"
              formControlName="intensity_filter"
            >
              <option value="" disabled>
                {{ 'OFFERS.FIELDS.INTENSITY_FILTER_PLACEHOLDER' | translate }}
              </option>
              <option
                *ngFor="
                  let option of getDropDownByName('intensity_filter')
                    ?.dropdown_options
                "
                [ngValue]="option.name"
              >
                {{ option.name }}
              </option>
            </select>
            <label class="field-description">
              {{ 'OFFERS.DESCRIPTIONS.INTENSITY_FILTER' | translate }}</label
            >
            <label
              class="text-danger"
              *ngIf="showErrors && hasError('intensity_filter')"
            >
              {{ errorFor('intensity_filter') }}
            </label>
          </div>

          <div class="form-group" *ngIf="getDropDownByName('child_care')">
            <label for="child_care">{{
              'OFFERS.FIELDS.CHILD_CARE' | translate
            }}</label>
            <select
              multiple
              class="custom-select {{ hasError('child_care') && 'is-invalid' }}"
              (change)="changeDropdownOption($event)"
              formControlName="child_care"
            >
              <option
                *ngFor="
                  let option of getDropDownByName('child_care')
                    ?.dropdown_options
                "
                [ngValue]="option.name"
              >
                {{ option.name }}
              </option>
            </select>
            <label class="field-description">
              {{ 'OFFERS.DESCRIPTIONS.CHILD_CARE' | translate }}</label
            >
            <label
              class="text-danger"
              *ngIf="showErrors && hasError('child_care')"
            >
              {{ errorFor('child_care') }}
            </label>
          </div>

          <div class="form-group" *ngIf="getDropDownByName('course_time')">
            <label for="course_time">{{
              'OFFERS.FIELDS.COURSE_TIME' | translate
            }}</label>
            <select
              multiple
              class="custom-select {{
                hasError('course_time') && 'is-invalid'
              }}"
              (change)="changeDropdownOption($event)"
              formControlName="course_time"
            >
              <option
                *ngFor="
                  let option of getDropDownByName('course_time')
                    ?.dropdown_options
                "
                [ngValue]="option.name"
              >
                {{ option.name }}
              </option>
            </select>
            <label class="field-description">
              {{ 'OFFERS.DESCRIPTIONS.COURSE_TIME' | translate }}</label
            >
            <label
              class="text-danger"
              *ngIf="showErrors && hasError('course_time')"
            >
              {{ errorFor('course_time') }}
            </label>
          </div>

          <div class="form-group" *ngIf="getDropDownByName('course_content')">
            <label for="course_content">{{
              'OFFERS.FIELDS.COURSE_CONTENT' | translate
            }}</label>
            <select
              multiple
              class="custom-select {{
                hasError('course_content') && 'is-invalid'
              }}"
              (change)="changeDropdownOption($event)"
              formControlName="course_content"
            >
              <option
                *ngFor="
                  let option of getDropDownByName('course_content')
                    ?.dropdown_options
                "
                [ngValue]="option.name"
              >
                {{ option.name }}
              </option>
            </select>
            <label class="field-description">
              {{ 'OFFERS.DESCRIPTIONS.COURSE_CONTENT' | translate }}</label
            >
            <label
              class="text-danger"
              *ngIf="showErrors && hasError('course_content')"
            >
              {{ errorFor('course_content') }}
            </label>
          </div>

          <div class="form-group" *ngIf="getDropDownByName('course_topic')">
            <label for="course_topic">{{
              'OFFERS.FIELDS.COURSE_TOPIC' | translate
            }}</label>
            <select
              multiple
              class="custom-select {{
                hasError('course_topic') && 'is-invalid'
              }}"
              (change)="changeDropdownOption($event)"
              formControlName="course_topic"
            >
              <option
                *ngFor="
                  let option of getDropDownByName('course_topic')
                    ?.dropdown_options
                "
                [ngValue]="option.name"
              >
                {{ option.name }}
              </option>
            </select>
            <label class="field-description">
              {{ 'OFFERS.DESCRIPTIONS.COURSE_TOPIC' | translate }}</label
            >
            <label
              class="text-danger"
              *ngIf="showErrors && hasError('course_topic')"
            >
              {{ errorFor('course_topic') }}
            </label>
          </div>

          <div class="form-group" *ngIf="getDropDownByName('integration_goal')">
            <label for="integration_goal">{{
              'OFFERS.FIELDS.INTEGRATION_GOAL' | translate
            }}</label>
            <select
              multiple
              class="custom-select {{
                hasError('integration_goal') && 'is-invalid'
              }}"
              (change)="changeDropdownOption($event)"
              formControlName="integration_goal"
            >
              <option
                *ngFor="
                  let option of getDropDownByName('integration_goal')
                    ?.dropdown_options
                "
                [ngValue]="option.name"
              >
                {{ option.name }}
              </option>
            </select>
            <label class="field-description">
              {{ 'OFFERS.DESCRIPTIONS.INTEGRATION_GOAL' | translate }}</label
            >
            <label
              class="text-danger"
              *ngIf="showErrors && hasError('integration_goal')"
            >
              {{ errorFor('integration_goal') }}
            </label>
          </div>

          <div class="form-group" *ngIf="getDropDownByName('modules')">
            <label for="place">{{ 'OFFERS.FIELDS.MODULES' | translate }}</label>
            <select
              multiple
              class="custom-select {{ hasError('modules') && 'is-invalid' }}"
              (change)="changeDropdownOption($event)"
              formControlName="modules"
            >
              <option
                *ngFor="
                  let option of getDropDownByName('modules')?.dropdown_options
                "
                [ngValue]="option.name"
              >
                {{ option.name }}
              </option>
            </select>
            <label class="field-description">
              {{ 'OFFERS.DESCRIPTIONS.MODULES' | translate }}</label
            >
            <label
              class="text-danger"
              *ngIf="showErrors && hasError('modules')"
            >
              {{ errorFor('modules') }}
            </label>
          </div>

          <div class="form-group" *ngIf="getDropDownByName('industry')">
            <label for="place">{{
              'OFFERS.FIELDS.INDUSTRY' | translate
            }}</label>
            <select
              multiple
              class="custom-select {{ hasError('industry') && 'is-invalid' }}"
              (change)="changeDropdownOption($event)"
              formControlName="industry"
            >
              <option
                *ngFor="
                  let option of getDropDownByName('industry')?.dropdown_options
                "
                [ngValue]="option.name"
              >
                {{ option.name }}
              </option>
            </select>
            <label class="field-description">
              {{ 'OFFERS.DESCRIPTIONS.INDUSTRY' | translate }}</label
            >
            <label
              class="text-danger"
              *ngIf="showErrors && hasError('industry')"
            >
              {{ errorFor('industry') }}
            </label>
          </div>

          <div class="form-group" *ngIf="getDropDownByName('workload')">
            <label for="workload">{{
              'OFFERS.FIELDS.WORKLOAD' | translate
            }}</label>
            <select
              multiple
              class="custom-select {{ hasError('workload') && 'is-invalid' }}"
              (change)="changeDropdownOption($event)"
              formControlName="workload"
            >
              <option
                *ngFor="
                  let option of getDropDownByName('workload')?.dropdown_options
                "
                [ngValue]="option.name"
              >
                {{ option.name }}
              </option>
            </select>
            <label class="field-description">
              {{ 'OFFERS.DESCRIPTIONS.WORKLOAD' | translate }}</label
            >
            <label
              class="text-danger"
              *ngIf="showErrors && hasError('workload')"
            >
              {{ errorFor('workload') }}
            </label>
          </div>

          <div
            class="form-group"
            *ngIf="getDropDownByName('german_level_starting')"
          >
            <label for="german_level_starting">{{
              'OFFERS.FIELDS.GERMAN_LEVEL_STARTING' | translate
            }}</label>
            <select
              multiple
              class="custom-select {{
                hasError('german_level_starting') && 'is-invalid'
              }}"
              (change)="changeDropdownOption($event)"
              formControlName="german_level_starting"
            >
              <option
                *ngFor="
                  let option of getDropDownByName('german_level_starting')
                    ?.dropdown_options
                "
                [ngValue]="option.name"
              >
                {{ option.name }}
              </option>
            </select>
            <label class="field-description">
              {{
                'OFFERS.DESCRIPTIONS.GERMAN_LEVEL_STARTING' | translate
              }}</label
            >
            <label
              class="text-danger"
              *ngIf="showErrors && hasError('german_level_starting')"
            >
              {{ errorFor('german_level_starting') }}
            </label>
          </div>

          <div class="form-group" *ngIf="getDropDownByName('work_assignment')">
            <label for="work_assignment">{{
              'OFFERS.FIELDS.WORK_ASSIGNMENT' | translate
            }}</label>
            <select
              multiple
              class="custom-select {{
                hasError('work_assignment') && 'is-invalid'
              }}"
              (change)="changeDropdownOption($event)"
              formControlName="work_assignment"
            >
              <option
                *ngFor="
                  let option of getDropDownByName('work_assignment')
                    ?.dropdown_options
                "
                [ngValue]="option.name"
              >
                {{ option.name }}
              </option>
            </select>
            <label class="field-description">
              {{ 'OFFERS.DESCRIPTIONS.WORK_ASSIGNMENT' | translate }}</label
            >
            <label
              class="text-danger"
              *ngIf="showErrors && hasError('work_assignment')"
            >
              {{ errorFor('work_assignment') }}
            </label>
          </div>

          <div class="form-group" *ngIf="!offer.hide_intensity">
            <label for="intensity">{{
              'OFFERS.FIELDS.INTENSITY' | translate
            }}</label>
            <input
              class="form-control {{ hasError('intensity') && 'is-invalid' }}"
              type="text"
              name="intensity"
              formControlName="intensity"
            />
            <label class="field-description">
              {{ 'OFFERS.DESCRIPTIONS.INTENSITY' | translate }}</label
            >
            <label
              class="text-danger"
              *ngIf="showErrors && hasError('intensity')"
            >
              {{ errorFor('intensity') }}
            </label>
          </div>

          <!-- <div class="form-group">
            <label for="intensity_short">{{
              'OFFERS.FIELDS.INTENSITY_SHORT' | translate
            }}</label>
            <input
              class="form-control {{
                hasError('intensity_short') && 'is-invalid'
              }}"
              type="text"
              name="intensity_short"
              formControlName="intensity_short"
            />
            <label class="field-description">
              {{ 'OFFERS.DESCRIPTIONS.INTENSITY_SHORT' | translate }}</label
            >
            <label
              class="text-danger"
              *ngIf="showErrors && hasError('intensity_short')"
            >
              {{ errorFor('intensity_short') }}
            </label>
          </div> -->

          <div class="form-group">
            <label for="duration_detail"
              >{{ 'OFFERS.FIELDS.DURATION_DETAIL' | translate }}
            </label>
            <textarea
              class="form-control {{
                hasError('duration_detail') && 'is-invalid'
              }}"
              name="duration_detail"
              type="text"
              rows="3"
              formControlName="duration_detail"
              maxlength="500"
            ></textarea>
            <label class="field-description">
              {{ 'OFFERS.DESCRIPTIONS.DURATION_DETAIL' | translate }}</label
            >
            <label
              class="text-danger"
              *ngIf="showErrors && hasError('duration_detail')"
            >
              {{ errorFor('duration_detail') }}
            </label>
          </div>

          <div class="form-group">
            <label for="entry_time">{{
              'OFFERS.FIELDS.ENTRY_TIME' | translate
            }}</label>
            <input
              class="form-control {{ hasError('entry_time') && 'is-invalid' }}"
              type="text"
              name="entry_time"
              formControlName="entry_time"
            />
            <label class="field-description">
              {{ 'OFFERS.DESCRIPTIONS.ENTRY_TIME' | translate }}</label
            >
            <label
              class="text-danger"
              *ngIf="showErrors && hasError('entry_time')"
            >
              {{ errorFor('entry_time') }}
            </label>
          </div>

          <div class="form-group">
            <app-offer-detail-accordion
              [label]="'Preistabelle'"
              [data]="offer.offer_pricings"
              [fields]="OFFER_DETAIL_ACCORDION_FIELDS"
              [fieldsOther]="OFFER_DETAIL_ACCORDION_FIELDS_OTHER"
              [columnWidths]="OFFER_DETAIL_ACCORDION_COL_WIDTHS"
              [noTableSplitting]="offer.no_price_table_splitting"
              [noBaseOffer]="offer.no_base_offer"
              [expanded]="true"
              model="OFFER_PRICINGS"
              type="table"
            ></app-offer-detail-accordion>
            <label class="field-description">
              {{ 'OFFERS.DESCRIPTIONS.PRICING_TABLE' | translate }}</label
            >
          </div>

          <div class="form-group">
            <label for="cancellattion_policy">{{
              'OFFERS.FIELDS.CANCELLATION_POLICY' | translate
            }}</label>
            <textarea
              class="form-control {{
                hasError('cancellation_policy') && 'is-invalid'
              }}"
              name="cancellation_policy"
              rows="5"
              formControlName="cancellation_policy"
              maxlength="1000"
            ></textarea>
            <label class="field-description">
              {{ 'OFFERS.DESCRIPTIONS.CANCELLATION_POLICY' | translate }}</label
            >
            <label
              class="text-danger"
              *ngIf="showErrors && hasError('cancellation_policy')"
            >
              {{ errorFor('cancellation_policy') }}
            </label>
          </div>

          <div class="form-group">
            <label for="internal_reference"
              >{{ 'OFFERS.FIELDS.INTERNAL_REFERENCE' | translate }}
            </label>
            <input
              class="form-control {{
                hasError('internal_reference') && 'is-invalid'
              }}"
              name="internal_reference"
              type="text"
              formControlName="internal_reference"
            />
            <label class="field-description">
              {{ 'OFFERS.DESCRIPTIONS.INTERNAL_REFERENCE' | translate }}</label
            >
            <label
              class="text-danger"
              *ngIf="showErrors && hasError('internal_reference')"
            >
              {{ errorFor('internal_reference') }}
            </label>
          </div>

          <div class="form-group">
            <label for="external_url">{{
              'OFFERS.FIELDS.EXTERNAL_URL' | translate
            }}</label>
            <input
              class="form-control {{
                hasError('external_url') && 'is-invalid'
              }}"
              name="external_url"
              type="text"
              formControlName="external_url"
            />
            <label class="field-description">
              {{ 'OFFERS.DESCRIPTIONS.EXTERNAL_URL' | translate }}</label
            >
            <label
              class="text-danger"
              *ngIf="showErrors && hasError('external_url')"
            >
              {{ 'ERRORS.URL' | translate }}
            </label>
          </div>

          <div class="form-group">
            <label for="external_url_2">{{
              'OFFERS.FIELDS.EXTERNAL_URL_2' | translate
            }}</label>
            <input
              class="form-control {{
                hasError('external_url_2') && 'is-invalid'
              }}"
              name="external_url_2"
              type="text"
              formControlName="external_url_2"
            />
            <label class="field-description">
              {{ 'OFFERS.DESCRIPTIONS.EXTERNAL_URL_2' | translate }}</label
            >
            <label
              class="text-danger"
              *ngIf="showErrors && hasError('external_url_2')"
            >
              {{ 'ERRORS.URL' | translate }}
            </label>
          </div>

          <div class="form-group">
            <label for="external_offer_url">{{
              'OFFERS.FIELDS.EXTERNAL_OFFER_URL' | translate
            }}</label>
            <input
              class="form-control {{
                hasError('external_offer_url') && 'is-invalid'
              }}"
              type="text"
              name="external_offer_url"
              formControlName="external_offer_url"
            />
            <label class="field-description">
              {{ 'OFFERS.DESCRIPTIONS.EXTERNAL_OFFER_URL' | translate }}</label
            >
            <label
              class="text-danger"
              *ngIf="showErrors && hasError('external_offer_url')"
            >
              {{ 'ERRORS.URL' | translate }}
            </label>
          </div>

          <div class="form-group">
            <label for="external_offer_url_2">{{
              'OFFERS.FIELDS.EXTERNAL_OFFER_URL_2' | translate
            }}</label>
            <input
              class="form-control {{
                hasError('external_offer_url_2') && 'is-invalid'
              }}"
              type="text"
              name="external_offer_url_2"
              formControlName="external_offer_url_2"
            />
            <label class="field-description">
              {{
                'OFFERS.DESCRIPTIONS.EXTERNAL_OFFER_URL_2' | translate
              }}</label
            >
            <label
              class="text-danger"
              *ngIf="showErrors && hasError('external_offer_url_2')"
            >
              {{ 'ERRORS.URL' | translate }}
            </label>
          </div>

          <div class="form-group">
            <label for="phone">{{ 'OFFERS.FIELDS.PHONE' | translate }} </label>
            <input
              class="form-control {{ hasError('phone') && 'is-invalid' }}"
              name="phone"
              type="text"
              formControlName="phone"
            />
            <label class="field-description">
              {{ 'OFFERS.DESCRIPTIONS.PHONE' | translate }}</label
            >
            <label class="text-danger" *ngIf="showErrors && hasError('phone')">
              {{ errorFor('phone') }}
            </label>
          </div>

          <div class="form-group">
            <label for="contact_email">{{
              'OFFERS.FIELDS.CONTACT_EMAIL' | translate
            }}</label>
            <input
              class="form-control {{
                hasError('contact_email') && 'is-invalid'
              }}"
              name="contact_email"
              type="text"
              formControlName="contact_email"
              email
            />
            <label class="field-description">
              {{ 'OFFERS.DESCRIPTIONS.CONTACT_EMAIL' | translate }}</label
            >
            <label
              class="text-danger"
              *ngIf="showErrors && hasError('contact_email')"
            >
              {{ errorFor('contact_email') }}
            </label>
          </div>

          <div class="form-group">
            <label for="author_email">{{
              'OFFERS.FIELDS.AUTHOR_EMAIL' | translate
            }}</label>
            <input
              class="form-control {{
                hasError('author_email') && 'is-invalid'
              }}"
              name="author_email"
              type="text"
              formControlName="author_email"
              email
            />
            <label class="field-description">
              {{ 'OFFERS.DESCRIPTIONS.AUTHOR_EMAIL' | translate }}</label
            >
            <label
              class="text-danger"
              *ngIf="showErrors && hasError('author_email')"
            >
              {{ errorFor('author_email') }}
            </label>
          </div>

          <div class="form-group">
            <label for="contact_person">{{
              'OFFERS.FIELDS.CONTACT_PERSON' | translate
            }}</label>
            <input
              class="form-control {{
                hasError('contact_person') && 'is-invalid'
              }}"
              name="contact_person"
              type="text"
              formControlName="contact_person"
            />
            <label class="field-description">
              {{ 'OFFERS.DESCRIPTIONS.CONTACT_PERSON' | translate }}</label
            >
            <label
              class="text-danger"
              *ngIf="showErrors && hasError('contact_person')"
            >
              {{ errorFor('contact_person') }}
            </label>
          </div>

          <div class="d-flex flex-row justify-content-end">
            <button
              class="btn btn-primary btn-wide mr-2 font-normal"
              (click)="update()"
            >
              {{ 'COMMON.SAVE' | translate }}
            </button>
            <button class="btn btn-primary mr-2" (click)="updateAndPreview()">
              {{ 'ADMIN.SAVE_AND_PREVIEW' | translate }}
            </button>
            <button
              class="btn btn-primary"
              (click)="publish()"
              *ngIf="offer.state == 'draft'"
            >
              {{ 'ADMIN.SAVE_AND_PUBLISH' | translate }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </app-page-content>
</ng-container>

<ng-template #offerNotFoundTemplate>
  <h1 *ngIf="!loading">{{ 'ADMIN.OFFER_NOT_FOUND' | translate }}</h1>
  <div class="list-group-item text-center" *ngIf="loading">
    <div class="spinner-border text-dark" role="status">
      <span class="sr-only">{{ 'COMMON.LOADING' | translate }}</span>
    </div>
  </div>
</ng-template>
