import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import Offer from 'src/app/definitions/Offer.interface';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-offer-item-mobile',
  templateUrl: './offer-item-mobile.component.html',
  styleUrls: ['./offer-item-mobile.component.scss']
})
export class OfferItemMobileComponent {
  @Input() offer: Offer;
  @Input() cssClass = '';

  constructor(private router: Router, private utilService: UtilService) {}

  navigateToDetail(): void {
    const slug = this.utilService.getOfferDetailSlug(this.offer);

    this.router.navigate(['/detail', slug]);
  }
}
