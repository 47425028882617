<section class="container">
  <div class="filters">
    <h2>{{ 'HOME.FILTER-TITLE' | translate }}</h2>
    <div class="row">
      <div class="col-12 mb-3">
        <input
        type="text"
        [formControl]="searchTermControl"
        class="form-control search-box"
        placeholder="{{ 'HOME.SEARCH_PLACEHOLDER' | translate }}">
      </div>
    </div>
    <div class="row">
      <ng-container
        *ngFor="
          let cat of dataService.offerTypes | slice: 0:4;
          index as categoryIndex
        "
      >
        <div class="col-xl-3 col-lg-4 col-md-6 mb-3">
          <ul class="list-group">
            <!-- TODO colorize based on API -->
            <li
              class="list-group-item filters__item--main {{ utilsService.categoryColorForDepartment(cat.name) }}"
            >
              <div class="custom-control custom-checkbox">
                <input
                  [checked]="categoryChecked(cat)"
                  (change)="toggleSubCategories(cat)"
                  type="checkbox"
                  class="filters__toggle custom-control-input"
                  id="cat-{{ categoryIndex }}"
                />
                <label
                  class="custom-control-label main-category {{ utilsService.categoryColorForDepartment(cat.name) }}"
                  for="cat-{{ categoryIndex }}"
                  >{{ cat.name }}</label
                >
              </div>
            </li>
            <ng-container
            *ngFor="let subcat of cat.offer_types; index as offerIndex"
            >
              <li class="list-group-item filters__item--sub d-flex align-items-center justify-content-between">
                <div class="custom-control custom-checkbox">
                  <input
                    #customControl
                    [checked]="dataService.categorySelected(subcat.name)"
                    (change)="dataService.toggleCategory(subcat); customControl.blur()"
                    type="checkbox"
                    class="filters__toggle custom-control-input"
                    id="subcat-{{ categoryIndex }}{{ offerIndex }}"
                  />
                  <label
                    class="custom-control-label {{ utilsService.categoryColorForDepartment(cat.name) }}"
                    for="subcat-{{ categoryIndex }}{{ offerIndex }}"
                    >{{ subcat.name }}</label
                  >
                </div>
                <app-tooltip *ngIf="subcat.tooltip_info" [text]="subcat.tooltip_info"></app-tooltip>
              </li>
            </ng-container>
          </ul>
        </div>
      </ng-container>
    </div>

    <div class="row mt-2">
      <ng-container *ngFor="let dropdown of dataService.dropdownFields">
        <div
          class="col-lg-3 col-md-4 col-sm-6"
          *ngIf="dataService.dropdownOptionAvailable(dropdown)"
        >
          <app-filter-dropdown
            [label]="dropdown.name_short || dropdown.name"
            [options]="dropdown.dropdown_options"
            (selected)="filterDropdownSelected(event)"
          ></app-filter-dropdown>
        </div>
      </ng-container>
      <div class="existing-choise d-none d-sm-block">
        {{ 'HOME.OFFERS_COUNT_LABEL' | translate }}:&nbsp;<b>{{
          (dataService.filteredOffers).length
        }}</b>
      </div>
    </div>
  </div>
</section>

<section class="container bg-transparent px-0 py-0">
  <app-filter-selection
    [selected]="dataService.selectedOptions"
  ></app-filter-selection>
</section>
