import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

export interface SiteSetting {
  maintenance: boolean;
  maintenance_text: string;
}

@Injectable({
  providedIn: 'root'
})
export class SiteSettingService {
  API_URL = environment.apiUrl;

  siteSetting: SiteSetting = {
    maintenance: false,
    maintenance_text: ''
  };
  healthCheckDone: boolean;

  constructor(
    private http: HttpClient,
    private translateService: TranslateService,
    private router: Router
  ) {
    this.healthCheck();
  }

  healthCheck(): void {
    this.getSiteSetting().subscribe(
      (setting) => {
        this.siteSetting = setting;
        this.healthCheckDone = true;

        if (this.siteSetting.maintenance) {
          this.router.navigate(['/maintenance']);
        }
        if (
          !this.siteSetting.maintenance &&
          this.router.url.includes('maintenance')
        ) {
          this.router.navigate(['/']);
        }
      },
      () => {
        this.siteSetting = {
          maintenance_text: this.translateService.instant(
            'MAINTENANCE.GENERIC_TEXT'
          ),
          maintenance: true
        };
        this.router.navigate(['/maintenance']);
        this.healthCheckDone = true;
      }
    );
  }

  getSiteSetting(): Observable<SiteSetting> {
    return this.http.get<SiteSetting>(`${this.API_URL}/site_settings`);
  }
}
