import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  RecaptchaFormsModule,
  RecaptchaModule,
  RECAPTCHA_SETTINGS
} from 'ng-recaptcha';
import { MatomoModule } from 'ngx-matomo';
import { MarkdownModule } from 'ngx-markdown';
import { NgSelectModule } from '@ng-select/ng-select';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StlyeguideComponent } from './components/stlyeguide/stlyeguide.component';
import { HomeComponent } from './pages/home/home.component';
import { HeroComponent } from './components/hero/hero.component';
import { FiltersComponent } from './components/filters/filters.component';
import { OffersListComponent } from './components/offers-list/offers-list.component';
import { LinksListComponent } from './components/links-list/links-list.component';
import { FooterComponent } from './components/footer/footer.component';
import { OfferDetailComponent } from './pages/offer-detail/offer-detail.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { TopBarComponent } from './components/top-bar/top-bar.component';
import { PageContentComponent } from './components/page-content/page-content.component';
import { FilterDropdownComponent } from './components/filter-dropdown/filter-dropdown.component';
import { FilterSelectionComponent } from './components/filter-selection/filter-selection.component';
import { OfferItemComponent } from './components/offer-item/offer-item.component';
import { OffersListSortComponent } from './components/offers-list-sort/offers-list-sort.component';
import { OfferEditComponent } from './pages/offer-edit/offer-edit.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AlertComponent } from './components/alert/alert.component';
import { MaintenanceComponent } from './pages/maintenance/maintenance.component';
import { OfferDetailBodyComponent } from './components/offer-detail-body/offer-detail-body.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { ErrorComponent } from './pages/error/error.component';
import { OfferBookingComponent } from './pages/offer-booking/offer-booking.component';
import { OfferBookingBodyComponent } from './components/offer-booking-body/offer-booking-body.component';
import { environment } from 'src/environments/environment';
import { OfferItemMobileComponent } from './components/offer-item-mobile/offer-item-mobile.component';
import { MoreFiltersMobileComponent } from './pages/more-filters-mobile/more-filters-mobile.component';
import { FiltersMobileComponent } from './components/filters-mobile/filters-mobile.component';
import { FilterDropdownOptionsMobileComponent } from './pages/filter-dropdown-options-mobile/filter-dropdown-options-mobile.component';
import { NavigationComponent } from './components//navigation/navigation.component';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { FooterSocialMenuComponent } from './components/footer-social-menu/footer-social-menu.component';
import { OfferDetailAccordionComponent } from './components/offer-detail-accordion/offer-detail-accordion.component';
import { OfferPreviewComponent } from './pages/offer-preview/offer-preview.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    StlyeguideComponent,
    HomeComponent,
    HeroComponent,
    FiltersComponent,
    OffersListComponent,
    LinksListComponent,
    FooterComponent,
    OfferDetailComponent,
    PageHeaderComponent,
    TopBarComponent,
    PageContentComponent,
    FilterDropdownComponent,
    FilterSelectionComponent,
    OfferItemComponent,
    OffersListSortComponent,
    OfferEditComponent,
    AlertComponent,
    MaintenanceComponent,
    OfferDetailBodyComponent,
    NotFoundComponent,
    ErrorComponent,
    OfferBookingComponent,
    OfferBookingBodyComponent,
    OfferItemMobileComponent,
    MoreFiltersMobileComponent,
    FiltersMobileComponent,
    FilterDropdownOptionsMobileComponent,
    NavigationComponent,
    TooltipComponent,
    FooterSocialMenuComponent,
    OfferDetailAccordionComponent,
    OfferPreviewComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    MatomoModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    MarkdownModule.forRoot()
  ],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: environment.reCaptcha.siteKey }
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
