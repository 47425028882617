import { DataService } from './../../services/data.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-filter-dropdown',
  templateUrl: './filter-dropdown.component.html',
  styleUrls: ['./filter-dropdown.component.scss']
})
export class FilterDropdownComponent implements OnInit {
  @Input() label: string;
  @Input() dropdownSlug: string;
  @Input() options: any;
  @Input() disabled: boolean;
  @Output() selected: EventEmitter<boolean> = new EventEmitter();

  constructor(public dataService: DataService) { }

  ngOnInit(): void {
  }
}
