import { DataService, OfferType } from './../../services/data.service';
import { Component, OnInit, Input, OnChanges } from '@angular/core';
import Offer from 'src/app/definitions/Offer.interface';
import { Router } from '@angular/router';
import { UtilService } from 'src/app/services/util.service';
import LinkItem from 'src/app/definitions/LinkItem.interface';
import { TranslateService } from '@ngx-translate/core';
import { CONFIG } from 'src/app/config/config';

export interface OfferField {
  label: string;
  value: string;
  id?: string;
}

// adjust order here
export const OFFER_DETAILS_ATTRIBUTES = [
  'short_description',
  'place_detail',
  'municipalities_short',
  'target_group',
  'course_level',
  'child_care',
  'course_time',
  'course_content',
  'integration_goal',
  'course_topic',
  'industry',
  'modules',
  'workload',
  'german_level_starting',
  'work_assignment',
  'intensity',
  'duration_detail',
  'entry_time',
  'price_detail',
  'cancellation_policy',
  'internal_reference',
  'external_url',
  'external_offer_url',
  'phone',
  'contact_email',
  'registrations'
];

export const DEFAULT_FIELDS = [
  'institution',
  'phone',
  'contact_email',
  'external_url',
  // 'registrations',
  'short_description',
  'place_detail',
  'municipalities_short',
  'price_formatted',
  'cancellation_policy',
  'price_detail',
  'duration_detail',
  'intensity',
  'entry_time',
  'internal_reference',
  'external_url',
  'external_offer_url'
];

@Component({
  selector: 'app-offer-detail-body',
  templateUrl: './offer-detail-body.component.html',
  styleUrls: ['./offer-detail-body.component.scss']
})
export class OfferDetailBodyComponent implements OnInit, OnChanges {
  @Input() offer: Offer;
  @Input() offerType: OfferType;
  @Input() isPreview: boolean;
  offerFields: OfferField[];
  linkFileds: LinkItem[];
  url: string;
  email: string;
  offerIndex: number;

  // offer pricings
  OFFER_DETAIL_ACCORDION_FIELDS: string[] =
    CONFIG.OFFER_DETAIL_ACCORDION.ALLOWED_COLUMNS;
  OFFER_DETAIL_ACCORDION_FIELDS_OTHER: string[] =
    CONFIG.OFFER_DETAIL_ACCORDION.ALLOWED_COLUMNS_OTHER;
  OFFER_DETAIL_ACCORDION_COL_WIDTHS: string[] =
    CONFIG.OFFER_DETAIL_ACCORDION.COL_WIDTHS;

  constructor(
    private router: Router,
    private utilService: UtilService,
    private translateService: TranslateService,
    public dataService: DataService
  ) {}

  ngOnChanges(): void {
    // just for spec
    if (this.offer) {
      this.buildFields();
    }
  }

  ngOnInit(): void {
    // just for spec
    if (this.offer) {
      this.buildFields();
    }
  }

  buildRegistrations(): void {
    this.offer.registrations = [
      {
        TITLE: this.translateService.instant('OFFER_DETAIL.REGISTRATION_OURS'),
        routerLink: `/booking/${this.utilService.getOfferDetailSlug(
          this.offer
        )}`
      }
    ];

    if (this.offer.registration) {
      this.offer.registrations.push({
        TITLE: this.translateService.instant(
          'OFFER_DETAIL.REGISTRATION_CLIENT'
        ),
        LINK: this.offer.registration,
        newWindow: true
      });
    }
  }

  buildFields(): void {
    this.offerFields = [];
    this.buildRegistrations();
    OFFER_DETAILS_ATTRIBUTES.forEach((key) => {
      if (DEFAULT_FIELDS.includes(key) || this.hasAdditionalValue(key)) {
        if (key === 'intensity' && this.offer.hide_intensity) {
          return;
        }
        if (key === 'external_offer_url' && !this.offer[key]) {
          return;
        }
        this.offerFields.push({
          label: this.utilService.translateAttribute('offers', key),
          value:
            Array.isArray(this.offer[key]) && key !== 'registrations'
              ? this.offer[key].join(', ')
              : this.offer[key],
          id: key
        });
      }
    });
  }

  isEmail(value): boolean {
    if (typeof value === 'object') {
      if (value && value[0]) {
        value = value[0];
      } else {
        return false;
      }
    }
    const expressionEmail = /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/gi;
    const regex = new RegExp(expressionEmail);
    this.email = value.match(regex);
    return !!this.email;
  }

  isUrl(value): boolean {
    if (typeof value === 'object') {
      if (value && value[0]) {
        value = value[0];
      } else {
        return false;
      }
    }
    const expressionUrl = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
    const regex = new RegExp(expressionUrl);
    this.url = value.match(regex);
    return !!this.url;
  }

  hasAdditionalValue(key: string): boolean {
    return Boolean(
      this.offerType.dropdown_fields.find((field) => field.field_name === key)
    );
  }
}
