<div class="top-bar container" [ngClass]="{ 'top-bar--compact': compact }">
  <div class="brand d-flex align-items-center" routerLink="/">
    <div class="align-items-center">
      <img class="brand__img" src="../../../assets/images/logo-lion.svg" />
    </div>
    <div>
      <h1 class="brand__title">{{ 'HOME.TITLE_FIRST' | translate }}</h1>
      <h1 class="brand__title">{{ 'HOME.TITLE_SECOND' | translate }}</h1>
    </div>
  </div>
</div>
