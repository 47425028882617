<app-page-content>
  <div class="container">
    <div class="maintenance-box">
      <h1 class="heading">{{ 'MAINTENANCE.TITLE' | translate }}</h1>
      <p class="text mt-4">{{text}}</p>
    </div>
  </div>
</app-page-content>

<app-footer></app-footer>
