import { SiteSettingService } from './services/site-setting.service';
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatomoInjector } from 'ngx-matomo';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'kzh002-angebotsdatenbank-frontend';

  constructor(
    translate: TranslateService,
    private matomoInjector: MatomoInjector,
    private siteSettingservice: SiteSettingService
  ) {
    translate.setDefaultLang('de');
    if (environment.production) {
      this.matomoInjector.init(environment.matmo.url, environment.matmo.siteId);
    }
  }
}
