<div class="navigation" *ngIf="this.dataService.filteredOffers.length">
  <div class="navigation__current">
    <input type="text" class="navigation__input" [value]="currentPage" (keydown)="keyDownFunction($event)" (focusout)="focusOut($event)">
  </div>
  <div class="navigation__max-page"> {{ 'NAVIGATION.OF' | translate }} {{dataService.filteredOffers.length }} </div>
  <a class="navigation__arrow {{ !canGoPrevious() && 'navigation__arrow--disabled'}}" (click)="goToPreviousOffer()">
    <img src="../../../assets/icons/triangle-left.svg" *ngIf="canGoPrevious()">
    <img src="../../../assets/icons/triangle-left-white.svg" *ngIf="!canGoPrevious()">
  </a>
  <a class="navigation__arrow {{ !canGoNext() && 'navigation__arrow--disabled'}}" (click)="goToNextOffer()">
    <img src="../../../assets/icons/triangle-right.svg" *ngIf="canGoNext()">
    <img src="../../../assets/icons/triangle-right-white.svg" *ngIf="!canGoNext()">
  </a>
</div>
