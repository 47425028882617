import { OfferType } from './../../services/data.service';
import { Component, OnInit } from '@angular/core';
import Offer from 'src/app/definitions/Offer.interface';
import { DataService } from 'src/app/services/data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilService } from 'src/app/services/util.service';
import { Subscription } from 'rxjs';
import { Meta, Title } from '@angular/platform-browser';
import { MatomoTracker } from 'ngx-matomo';

@Component({
  selector: 'app-offer-detail',
  templateUrl: './offer-detail.component.html',
  styleUrls: ['./offer-detail.component.scss']
})
export class OfferDetailComponent implements OnInit {
  offer: Offer;
  offerSubscription: Subscription;
  offerType: OfferType;
  isLoading = false;
  isPreview = false;

  constructor(
    protected dataService: DataService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected matomoTracker: MatomoTracker,
    public utilService: UtilService,
    public meta: Meta,
    public title: Title
  ) {
    //
  }

  metaTags(): void {
    this.meta.addTag({
      name: 'description',
      content: this.offer.meta_description
    });
    this.title.setTitle(this.offer.meta_title);
  }

  async fetchOffer(): Promise<void> {
    const slug = this.route.snapshot.paramMap.get('offerSlug');
    const id = this.utilService.getIdFromSlug(slug);

    this.offer = await this.dataService.getOffer(id);

    this.formatWorkload();
  }

  formatWorkload(): void {
    if (this.offer.workload.length <= 1) {
      return;
    }
    const nums = this.offer.workload
      .map((num) => num.split(' - '))
      .toString()
      .split(',')
      .map((num) => parseInt(num, 10));

    this.offer.workload = [`${Math.min(...nums)}% - ${Math.max(...nums)}%`];
  }

  async ngOnInit(): Promise<void> {
    this.offerSubscription = this.dataService.offerChanged.subscribe(
      (newOffer) => {
        this.offer = newOffer;
        this.matomoTracker.trackPageView(`OfferDetail: ${this.offer.id}`);
        this.metaTags();
      }
    );

    this.isLoading = true;

    try {
      if (this.dataService.offerTypes.length === 0) {
        this.dataService.offerTypes = await this.dataService.getOfferTypes();
      }
      await this.fetchOffer();

      this.metaTags();
      this.isLoading = false;

      this.offerType = this.dataService.offerTypes
        .find((department) => {
          return department.name === this.offer.department_name;
        })
        .offer_types.find(
          (offerType) => offerType.name === this.offer.offer_type_name
        );
    } catch (e) {
      console.log(e);
      this.isLoading = false;
      switch (e.status) {
        case 404:
          this.router.navigate(['/404']);
          break;
        default:
          this.router.navigate(['/error']);
          break;
      }
    }
  }
}
