<div
  *ngIf="offer"
  class="offer-item bg-white mb-3 p-3"
  [ngClass]="cssClass"
  (click)="navigateToDetail()"
>
  <div class="d-flex justify-content-between align-items-center">
    <div>
      <div class="title">
        {{ offer.title }}
      </div>
      <div class="institution">
        {{ offer.institution }}
      </div>
      <div class="place">
        {{ offer.place }}
      </div>
      <div class="price">
        {{ offer.price_formatted }}
      </div>
    </div>
    <div class="arrow">
      <img src="../../../assets/images/expand-right-gray.svg" />
    </div>
  </div>
</div>
