import { Component, Input, OnInit } from '@angular/core';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent implements OnInit {
  @Input() text;
  show = false;

  constructor(public utilService: UtilService) { }

  ngOnInit(): void {
  }

  toggleShow(): void {
    this.show = !this.show;
    if (this.utilService.activeTooltip) {
      this.utilService.activeTooltip.show = false;
    }
    if (this.show) {
      this.utilService.activeTooltip = this;
    } else {
      this.utilService.activeTooltip = null;
    }
  }
}
