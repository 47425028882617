<app-page-content class="h-75">
  <div class="container p-5">
    <div class="row">
      <div class="col-md-4 d-flex align-items-center justify-content-center justify-content-md-end">
        <img class="not-found__logo mr-2" src="../../../assets/images/logo-lion-black.svg" />
        <img class="not-found__wappen mr-1" src="../../../assets/images/logo-wappen-black.svg" />
      </div>
      <div class="col-md-8">
        <h4 class="not-found__project mt-4 mt-md-0">Kantonale Angebotsdatenbank</h4>
        <h1 class="not-found__description mb-4 mt-4">{{'404.DESCRIPTION' | translate}}</h1>
        <p class="not-found__error">{{'404.ERROR' | translate}}</p>
        <p class="not-found__code mb-4">Fehlercode: 404</p>
        <button [routerLink]="['/home']" class="btn btn-primary mt-1 btn-wide" >Zur Startseite</button>
      </div>
    </div>
  </div>
</app-page-content>

<app-footer></app-footer>
